import React from 'react';
import { createRoot } from 'react-dom/client';
import Header from './header';

const Calculator = () => {
  return (
    <main className="calculator">
      <Header />
    </main>
  );
};

const root = createRoot(document.getElementById('calculator-root'));
root.render(<Calculator />);
