import React from 'react';

// this is a filler component. I wanted to demonstrate how to create and import other components.
const Header = () => (
  <>
    <h2>Calculator</h2>

    <p>This is the calculator.</p>
  </>
);

export default Header;
